import React from "react";

import { KLogoKraftfulSvg } from "app/core/components/KraftfulLogoSVG";
import Layout from "app/core/layouts/Layout";

export type EmptyGrayLayoutProps = {
  title?: string;
};

export const EmptyGrayLayout = ({
  children,
  title,
}: React.PropsWithChildren<EmptyGrayLayoutProps>) => (
  <Layout title={title}>
    <div className="min-h-full bg-gray-100 relative">{children}</div>
  </Layout>
);

export const LogoGrayLayout = ({
  children,
  title,
}: React.PropsWithChildren<EmptyGrayLayoutProps>) => (
  <EmptyGrayLayout title={title}>
    <div className="flex flex-col items-center text-gray-800">
      <KLogoKraftfulSvg className="mt-20" width={150} />
      <div className="flex-grow-0 w-full bg-white rounded shadow max-w-4xl mx-auto p-8 lg:p-24 mt-12 mb-20">
        {children}
      </div>
    </div>
  </EmptyGrayLayout>
);
