import { Routes, BlitzPage } from "@blitzjs/next";
import { useRouter } from "next/router";

import { KLogoKraftfulSvg } from "app/core/components/KraftfulLogoSVG";
import { useAnalytics } from "app/core/hooks/useAnalytics";
import { useQueryParam } from "app/core/hooks/useQueryParam";
import { EmptyGrayLayout } from "app/core/layouts/EmptyGrayLayout";

import { LoginForm } from "app/auth/components/LoginForm";

const LoginPage: BlitzPage = () => {
  const router = useRouter();
  const queryEmail = useQueryParam("email", "string");
  const { trackUser } = useAnalytics();

  return (
    <div className="flex flex-col items-center">
      <KLogoKraftfulSvg className="mt-20" width={150} />
      <div className="mt-12">
        <LoginForm
          initialEmail={queryEmail}
          onSuccess={(user) => {
            trackUser(user.id.toString(), {
              email: user.email,
            });
            const next = router.query.next
              ? decodeURIComponent(router.query.next as string)
              : Routes.Home().pathname;
            // Do a full page reload instead of router push to ensure new session
            window.location.href = next;
          }}
        />
      </div>
      <div className="flex flex-row">
        <a
          href="https://www.kraftful.com/terms-of-service"
          target="_blank"
          rel="noreferrer"
          className="text-black font-light mt-4"
        >
          Terms
        </a>
        <a
          href="https://www.kraftful.com/privacy-policy"
          target="_blank"
          rel="noreferrer"
          className="text-black font-light mt-4 ml-6"
        >
          Privacy
        </a>
      </div>
    </div>
  );
};

LoginPage.redirectAuthenticatedTo = Routes.Home();
LoginPage.suppressFirstRenderFlicker = true;
LoginPage.getLayout = (page) => <EmptyGrayLayout title="Log in">{page}</EmptyGrayLayout>;

export default LoginPage;
