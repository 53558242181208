import { useRouter } from "next/router";

/**
 * Parses a query string key as a string value
 */
export function useQueryParam(name: string, returnType: "string"): string | undefined;
/**
 * Parses a query string key as a number value
 */
export function useQueryParam(name: string, returnType: "number"): number | undefined;
export function useQueryParam(
  name: string,
  returnType: "string" | "number"
): number | string | undefined {
  const query = useRouter().query;
  const param = query[name];
  if (param == null) return undefined;

  if (returnType === "number") {
    if (Array.isArray(param)) {
      if (param[0] == null) return undefined;
      const num = parseInt(param[0], 10);
      if (isNaN(num)) return undefined;
      return num;
    }

    const num = parseInt(param, 10);
    if (isNaN(num)) return undefined;
    return num;
  } else if (returnType === "string") {
    if (Array.isArray(param)) return param[0];

    return param;
  }
}
